import BevableHome from 'features/bevable/BevableHome'
import Header from 'features/navigation/Header'
import Layout from 'common/components/Layout'
import LoadUser from 'features/auth/LoadUser'
import React from 'react'
import ReactDOM from 'react-dom/client'
import RouteError from 'common/components/RouteError'
import { APP_TITLE } from 'app/config'
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom'
import { defineCustomElements } from '@scania/tegel-react'
import { Helmet } from 'react-helmet'
import { msalConfig } from 'app/config/auth'
import { MsalProvider } from '@azure/msal-react'
import { Provider } from 'react-redux'
import { PublicClientApplication } from '@azure/msal-browser'
import { store } from 'app/store'
import { ToastProvider, UpdateApp } from '@optimization/ssi-common'
import './index.scss'

defineCustomElements()

export const msalInstance = new PublicClientApplication(msalConfig)

export const routes = {
  errorElement: <RouteError />,
  element: (
    <>
      <Helmet>
        <title>{APP_TITLE}</title>
      </Helmet>
      <LoadUser>
        <UpdateApp appTitle={APP_TITLE}>
          <Header />
          <Outlet />
        </UpdateApp>
      </LoadUser>
    </>
  ),
  children: [
    {
      element: (
        <Layout>
          <Outlet />
        </Layout>
      ),
      children: [
        {
          element: <BevableHome />,
          path: '/',
        },
        {
          path: 'callback',
        },
      ],
    },
  ],
}

const router = createBrowserRouter([routes])

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <ToastProvider>
          <RouterProvider router={router} />
        </ToastProvider>
      </Provider>
    </MsalProvider>
  </React.StrictMode>
)
