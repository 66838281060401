import { ReactElement, ReactNode } from 'react'
import { TdsButton, TdsIcon } from '@scania/tegel-react'
import styles from './style.module.scss'

interface Properties {
  children: ReactNode
  onPanelClose?: () => void
}

const Sidebar = ({ children, onPanelClose }: Properties): ReactElement => {
  return (
    <>
      <div className={styles['side-panel']}>
        <div className="tds-u-h-100">
          <div
            className="tds-u-align-center"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginLeft: '16px',
              alignItems: 'center',
            }}
          >
            <h4 style={{ marginTop: '12px' }}>Settings</h4>
            <div style={{ marginTop: '12px' }}>
              <TdsButton variant="ghost" size="md" onClick={onPanelClose}>
                <TdsIcon
                  slot="icon"
                  className="tds-u-p1"
                  name="cross"
                  size="16px"
                />
              </TdsButton>
            </div>
          </div>
          <div className="tds-u-m2">{children}</div>
        </div>
      </div>
    </>
  )
}

export default Sidebar
