import Login from 'features/auth/Login'
import PageAccessDenied from 'common/components/PageAccessDenied'
import { useAppSelector } from 'app/hooks'
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react'

const Layout = ({ children }: { children: React.ReactNode }) => {
  const accessDenied = useAppSelector((state) => state.auth.accessDenied)
  const accessToken = useAppSelector((state) => state.auth.accessToken)

  return (
    <>
      <main>
        {accessDenied ? (
          <PageAccessDenied className="mt-spacing-144" />
        ) : (
          <>
            <AuthenticatedTemplate>
              {accessToken && children}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <Login />
            </UnauthenticatedTemplate>
          </>
        )}
      </main>
    </>
  )
}

export default Layout
