import { getErrorMessage, logError } from 'common/utils/helper'
import { useEffect, useMemo } from 'react'
import { useRouteError } from 'react-router-dom'
import { useToast } from '@optimization/ssi-common'

const RouteError = () => {
  const showToast = useToast()

  const error = useRouteError()

  const message: string = useMemo(() => getErrorMessage(error), [error])

  useEffect(() => {
    if (message) {
      showToast({
        header: 'Something went wrong',
        subheader: 'Reload the page or try it again later',
        variant: 'error',
      })

      logError(message)
    }
  }, [message, showToast])

  return null
}

export default RouteError
