import Mapbox from './Mapbox'
import { MapRef } from 'react-map-gl'
import { useRef, useState } from 'react'
import Sidebar from './Sidebar'
import EvaluationOfChassisFilters from './EvaluationOfChassisFilters'
import { TdsAccordion, TdsAccordionItem } from '@scania/tegel-react'
import {
  ChassisEvaluationFilterRequest,
  Percentiles,
  useGetStopsQuery,
} from 'src/app/services/bevable'
import SettingsFilters from './SettingsFilters'
import MarketFilter from './MarketFIlter'
import { Button } from '@optimization/ssi-common'
import styles from './style.module.scss'

const BevableHome = () => {
  const mapRef = useRef<MapRef | null>(null)
  const [showIsBevable, setShowIsBevable] = useState(true)
  const [showSidebar, setShowSidebar] = useState(false)
  const [stopsRequest, setStopsRequest] =
    useState<ChassisEvaluationFilterRequest>({
      Resolution: 5,
      DailyDistance: Percentiles.P50th,
      MaxGtw: Percentiles.P50th,
      FilterOnChassisAdaptation: true,
      FilterOnWheelConfiguration: true,
      FilterOnAxleDistance: true,
      FilterOnChassisHeight: true,
      FilterOnCabType: true,
      Markets: [],
    })

  const [filterItems, setFilterItems] =
    useState<ChassisEvaluationFilterRequest>(stopsRequest)

  const [minimumValue, setMinimumValue] = useState<number>(3)

  const stops = useGetStopsQuery(stopsRequest)

  const handleMapboxMenuOption = (option: string) => {
    if (option === 'settings') {
      setShowSidebar(!showSidebar)
    }
  }

  const buttonType = stopsRequest === filterItems ? 'secondary' : 'primary'
  return (
    <>
      <div className={showSidebar ? '' : 'tds-u-display-none'}>
        <Sidebar onPanelClose={() => setShowSidebar(false)}>
          <TdsAccordion>
            <TdsAccordionItem header="Technical evaluation of chassis" expanded>
              <div className="tds-accordion-item-margin-override">
                <EvaluationOfChassisFilters
                  initialValues={filterItems}
                  onFilterUpdate={setFilterItems}
                />
              </div>
            </TdsAccordionItem>
            <TdsAccordionItem header="Markets">
              <div className="tds-accordion-item-margin-override">
                <MarketFilter
                  initialValues={filterItems}
                  onMarketUpdate={setFilterItems}
                />
              </div>
            </TdsAccordionItem>
            <TdsAccordionItem header="Location">
              <div className="tds-accordion-item-margin-override">
                <SettingsFilters
                  showIsBevable={showIsBevable}
                  setShowIsBevable={setShowIsBevable}
                  initialValues={filterItems}
                  onResolutionUpdate={setFilterItems}
                  onMinimumValueUpdate={setMinimumValue}
                  minimumValue={minimumValue}
                />
              </div>
            </TdsAccordionItem>
          </TdsAccordion>
          <div className={styles['evaluation-button']}>
            <Button
              text="Evaluate"
              size="md"
              variant={buttonType}
              modeVariant="primary"
              onClick={() => setStopsRequest(filterItems)}
            />
          </div>
        </Sidebar>
      </div>
      <Mapbox
        mapRef={mapRef}
        isSuccessStops={stops.isSuccess}
        isFetching={stops.isFetching}
        isError={stops.isError}
        stops={stops.isSuccess ? stops.data : undefined}
        showIsBevable={showIsBevable}
        onMenuSelect={handleMapboxMenuOption}
        stopsRequest={stopsRequest}
        minimumValue={minimumValue}
      />
    </>
  )
}

export default BevableHome
