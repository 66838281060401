import { Dispatch, SetStateAction } from 'react'
import styles from './style.module.scss'
import { ChassisEvaluationFilterRequest } from 'src/app/services/bevable'
import SidebarFilterToggle from '../EvaluationOfChassisFilters/SidebarFIlterToggle'
import Slider from './Slider'

interface Props {
  showIsBevable: boolean
  setShowIsBevable: Dispatch<SetStateAction<boolean>>
  initialValues: ChassisEvaluationFilterRequest
  onResolutionUpdate: Dispatch<SetStateAction<ChassisEvaluationFilterRequest>>
  onMinimumValueUpdate: Dispatch<SetStateAction<number>>
  minimumValue: number
}

const SettingsFilters = ({
  showIsBevable,
  setShowIsBevable,
  initialValues,
  onResolutionUpdate,
  onMinimumValueUpdate,
  minimumValue,
}: Props) => {
  return (
    <div className={styles['filter-container']}>
      <div>
        <SidebarFilterToggle
          checked={showIsBevable}
          label="Valid Evaluation"
          onChange={(value) => setShowIsBevable(value)}
        />
        <p>
          {showIsBevable
            ? 'Showing locations with valid candidates'
            : 'Showing locations with invalid candidates'}
        </p>
      </div>
      <Slider
        min="1"
        max="20"
        value={minimumValue}
        label="Minimum candidates per hexagon"
        onChange={(value) => {
          onMinimumValueUpdate(+value)
        }}
      />
      <Slider
        min="3"
        max="10"
        value={initialValues.Resolution}
        label="Graph resolution"
        onChange={(value) => {
          const newValues = { ...initialValues }
          newValues.Resolution = +value
          onResolutionUpdate(newValues)
        }}
      />
    </div>
  )
}

export default SettingsFilters
