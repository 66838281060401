import classes from './style.module.scss'
import DeckGL, { FlyToInterpolator, H3HexagonLayer } from 'deck.gl/typed'
import { Map, MapRef } from 'react-map-gl'
import { MAPBOX_API_KEY } from 'app/config'
import { useCallback, useMemo, useState } from 'react'
import {
  ChassisEvaluation,
  ChassisEvaluationFilterRequest,
  StopsResponse,
} from 'src/app/services/bevable'
import Popover from './Popover'
import { TdsButton, TdsIcon } from '@scania/tegel-react'
import { Loading } from '@optimization/ssi-common'

interface Props {
  mapRef: React.RefObject<MapRef>
  isSuccessStops: boolean
  isFetching: boolean
  isError: boolean
  stops: StopsResponse | undefined
  showIsBevable: boolean
  minimumValue: number
  onMenuSelect: (option: string) => void
  stopsRequest: ChassisEvaluationFilterRequest
}

const Mapbox = ({
  mapRef,
  stopsRequest,
  stops,
  minimumValue,
  isSuccessStops,
  isFetching,
  isError,
  showIsBevable,
  onMenuSelect,
}: Props) => {
  const [showPopup, setShowPopup] = useState(false)
  const [popupInfo, setPopupInfo] = useState<ChassisEvaluation>({
    H3CellIndex: '',
    ExecutableCount: 0,
    NonExecutableCount: 0,
  })
  const [initialViewState] = useState({
    latitude: 59.334591,
    longitude: 18.06324,
    zoom: 5,
    bearing: 0,
    pitch: 30,
  })

  const onOpenPopup = useCallback(
    ({
      H3CellIndex,
      ExecutableCount,
      NonExecutableCount,
    }: ChassisEvaluation) => {
      //set timeout to avoid clickOutsideEvent taking over and closing the popup
      setTimeout(() => {
        setShowPopup(true)
        setPopupInfo({
          H3CellIndex,
          ExecutableCount,
          NonExecutableCount,
        })
      }, 50)
    },
    []
  )

  const onClosePopup = useCallback(() => {
    setShowPopup(false)
  }, [])

  const layers = useMemo(() => {
    return [
      new H3HexagonLayer({
        id: 'h3-hexagon-layer',
        onClick: (value) => {
          onOpenPopup({ ...value.object })
        },
        data: stops?.ChassisEvaluations.filter((element) => {
          if (showIsBevable) return element.ExecutableCount >= minimumValue

          return element.NonExecutableCount >= minimumValue
        }),
        pickable: true,
        wireframe: false,
        filled: true,
        extruded: true,
        elevationScale: 750,
        autoHighlight: true,
        getHexagon: (cell: ChassisEvaluation) => cell.H3CellIndex,
        getFillColor: (cell: ChassisEvaluation) => {
          const countToColor = showIsBevable
            ? cell.ExecutableCount
            : cell.NonExecutableCount
          return showIsBevable
            ? [
                Math.max(22, 277 - 5 * countToColor),
                Math.max(55, 310 - 5 * countToColor),
                Math.max(127, 382 - 5 * countToColor),
                Math.max(205, (200 * countToColor) / 255),
              ]
            : [
                Math.max(214, 469 - 5 * countToColor),
                Math.max(0, 255 - 5 * countToColor),
                Math.max(28, 283 - 5 * countToColor),
                Math.max(205, (200 * countToColor) / 255),
              ]
        },
        getElevation: (cell: ChassisEvaluation) =>
          showIsBevable ? cell.ExecutableCount : cell.NonExecutableCount,
      }),
    ]
  }, [stops, onOpenPopup, showIsBevable, minimumValue])

  return (
    <>
      {!isSuccessStops && (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '20',
          }}
        >
          <Loading isLoading={!isSuccessStops} isError={isError} />
        </div>
      )}
      {isFetching && (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '20',
          }}
        >
          <Loading isLoading={isFetching} isError={isError} />
        </div>
      )}
      <div className={classes.map}>
        <DeckGL
          height="calc(100vh - 60px)"
          controller={{
            dragPan: true,
            doubleClickZoom: false,
            scrollZoom: true,
            keyboard: false,
          }}
          initialViewState={{
            ...initialViewState,
            padding: { top: 20, bottom: 20, left: 20, right: 20 },
            transitionDuration: 2000,
            transitionInterpolator: new FlyToInterpolator(),
          }}
          layers={layers}
        >
          {stops !== undefined && (
            <div
              style={{
                zIndex: 200,
                position: 'absolute',
                height: '76px',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                backdropFilter: 'blur(1px)',
              }}
            >
              <div
                style={{
                  zIndex: 210,
                  display: 'flex-col',
                  alignSelf: 'center',
                  justifyContent: 'center',
                  alignContent: 'center',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <h3>
                  {showIsBevable
                    ? `${stops.TotalExecutableCount.toLocaleString(
                        'en'
                      ).replace(/,/g, ' ')} valid BEV candidates`
                    : `${stops.TotalNonExecutableCount.toLocaleString(
                        'en'
                      ).replace(/,/g, ' ')} invalid BEV candidates`}
                </h3>
                <h6
                  style={{
                    textAlign: 'center',
                  }}
                >{`out of ${stops.TotalChassisEvaluated.toLocaleString(
                  'en'
                ).replace(/,/g, ' ')} evaluated chassis`}</h6>
              </div>
              <div className={classes.cog}>
                <TdsButton
                  variant="ghost"
                  onClick={() => onMenuSelect('settings')}
                >
                  <TdsIcon slot="icon" size="20px" name="settings"></TdsIcon>
                </TdsButton>
              </div>
            </div>
          )}
          <Map
            ref={mapRef}
            mapboxAccessToken={MAPBOX_API_KEY}
            mapStyle="mapbox://styles/ssi-team/cln1ihy46030401qu78e18urd"
            // @ts-expect-error mercator projection is missing in type
            projection="mercator"
          />
        </DeckGL>
        {showPopup && (
          <Popover
            style={{
              position: 'relative',
              width: '300px',
              marginTop: '10%',
              marginInline: 'auto',
            }}
            hidePopover={onClosePopup}
            show
            selectedMarker={{
              stopLocation: popupInfo,
              showIsBevable: showIsBevable,
              stopsRequest: stopsRequest,
            }}
            header="Stop Location Information"
          ></Popover>
        )}
      </div>
    </>
  )
}

export default Mapbox
