export const APP_TITLE = import.meta.env.VITE_APP_TITLE

export const MODE = import.meta.env.MODE

export const BEVABLE_ENDPOINT = import.meta.env.VITE_BEVABLE_ENDPOINT

export const LOCALE_DATE = 'en-GB'

export const MAP_ANIMATION_SPEED = 2

export const APP_IS_UPDATED_STORAGE_KEY = 'appIsUpdated'

export const MAPBOX_API_KEY = import.meta.env.VITE_MAPBOX_API_KEY

export const SUPPORT_NAME = 'Jakob Petersén'
export const SUPPORT_EMAIL = 'jakob.petersen@scania.com'
