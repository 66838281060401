import { useCallback } from 'react';
import { TdsSliderCustomEvent } from '@scania/tegel';
import { TdsSlider } from '@scania/tegel-react';

type SliderEvent = TdsSliderCustomEvent<{ value: string }>;

interface Props {
  onChange: (value: string) => void;
  label: string;
  min: string;
  max: string;
  value: string | number;
  disabled?: boolean;
  className?: string;
}

const Slider = ({
  onChange,
  label,
  min,
  max,
  value,
  disabled = false,
  className,
}: Props) => {
  const onTdsChange = useCallback(
    (event: SliderEvent) => {
      onChange(event.detail.value);
    },
    [onChange]
  );

  return (
    <div className={className}>
      <TdsSlider
        min={min}
        max={max}
        value={`${value}`}
        label={label}
        input
        disabled={disabled}
        scrubber-size="lg"
        onTdsChange={onTdsChange}
      />
    </div>
  );
};

export default Slider;
