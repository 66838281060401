//Todo: Replace with the popover menu component from Tegel when the show property works correctly
import { ReactNode, useRef, CSSProperties } from 'react'
import classNames from 'classnames'
import { TdsIcon } from '@scania/tegel-react'

import './style.scss'
import useOutsideClick from '../../../../../hooks/useOutsideClick'
import {
  ChassisEvaluation,
  ChassisEvaluationFilterRequest,
  Company,
  useGetHexQuery,
} from 'src/app/services/bevable'

interface Props {
  toggle?: ReactNode
  show: boolean
  header?: ReactNode
  variant?: 'menu' | 'tooltip'
  style?: CSSProperties
  hidePopover: () => void
  selectedMarker: {
    stopLocation: ChassisEvaluation
    showIsBevable: boolean
    stopsRequest: ChassisEvaluationFilterRequest
  }
}

const Popover = ({
  toggle,
  show,
  style,
  header,
  variant = 'tooltip',
  hidePopover,
  selectedMarker,
}: Props) => {
  const toggleRef = useRef<HTMLDivElement | null>(null)
  const popoverRef = useOutsideClick(hidePopover, toggleRef)

  const hexRequest = {
    Resolution: selectedMarker.stopsRequest.Resolution,
    DailyDistance: selectedMarker.stopsRequest.DailyDistance,
    MaxGtw: selectedMarker.stopsRequest.MaxGtw,
    FilterOnChassisAdaptation:
      selectedMarker.stopsRequest.FilterOnChassisAdaptation,
    FilterOnWheelConfiguration:
      selectedMarker.stopsRequest.FilterOnWheelConfiguration,
    FilterOnAxleDistance: selectedMarker.stopsRequest.FilterOnAxleDistance,
    FilterOnChassisHeight: selectedMarker.stopsRequest.FilterOnChassisHeight,
    FilterOnCabType: selectedMarker.stopsRequest.FilterOnCabType,
    Markets: selectedMarker.stopsRequest.Markets,
    H3CellIndex: selectedMarker.stopLocation.H3CellIndex,
  }
  const hexDetails = useGetHexQuery(hexRequest)

  return (
    <div className="popover">
      {toggle && <div ref={toggleRef}>{toggle}</div>}
      {show && (
        <div
          ref={popoverRef}
          style={style}
          className={classNames('popover-inner', `popover-inner-${variant}`)}
        >
          {variant === 'tooltip' && (
            <>
              {header && (
                <div
                  className={classNames(
                    `popover-inner-${variant}-header`,
                    'tds-detail-05'
                  )}
                >
                  <div
                    className="pl-spacing-8"
                    style={{ padding: '2px', margin: '2px' }}
                  >
                    {header}
                  </div>
                  <button
                    onClick={hidePopover}
                    className={classNames(
                      `popover-inner-${variant}-header-close`,
                      'tds-u-ml-auto',
                      'reset-button-styles',
                      'px-spacing-8',
                      'py-spacing-8'
                    )}
                  >
                    <TdsIcon name="cross" size="16px" />
                  </button>
                </div>
              )}
            </>
          )}
          <div
            className={classNames(`popover-inner-${variant}-body`)}
            style={{ maxHeight: '300px', overflowY: 'auto' }}
          >
            <div className="tds-detail-05">
              <div className="flex justify-between">
                <div
                  className="color-grey-800-in-light-mode color-grey-200-in-dark-mode"
                  style={{ marginBottom: '10px' }}
                >
                  {selectedMarker.showIsBevable
                    ? 'Potential Bev Candidates'
                    : 'Invalid Bev Candidates'}
                </div>
                <div className="tds-u-ml1">
                  {selectedMarker.showIsBevable
                    ? selectedMarker.stopLocation.ExecutableCount
                    : selectedMarker.stopLocation.NonExecutableCount}
                </div>
              </div>
            </div>
            <div className="tds-detail-05">
              <div className="flex justify-between">
                <div
                  className="color-grey-800-in-light-mode color-grey-200-in-dark-mode"
                  style={{ marginBottom: '10px' }}
                >
                  {hexDetails.data !== undefined &&
                  hexDetails.data.Companies !== undefined &&
                  selectedMarker.showIsBevable
                    ? 'Customers with potential BEV candidates'
                    : 'Customers with invalid BEV candidates'}
                </div>
                <div className="tds-u-ml1">
                  {selectedMarker.showIsBevable &&
                    hexDetails.data !== undefined &&
                    hexDetails.data.Companies !== undefined && (
                      <p>
                        {
                          hexDetails.data.Companies.filter(
                            (company: Company) => company.ExecutableCount > 0
                          ).length
                        }
                      </p>
                    )}
                  {!selectedMarker.showIsBevable &&
                    hexDetails.data !== undefined &&
                    hexDetails.data.Companies !== undefined && (
                      <p>
                        {
                          hexDetails.data.Companies.filter(
                            (company: Company) => company.NonExecutableCount > 0
                          ).length
                        }
                      </p>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Popover
