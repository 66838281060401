import { loginRequest } from 'app/config/auth'
import { useCallback } from 'react'
import { useMsal } from '@azure/msal-react'

const useLogin = () => {
  const { instance } = useMsal()

  return useCallback(() => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e)
    })
  }, [instance])
}

export default useLogin
