import useLoadAuthTokens from 'features/auth/utils/useLoadAuthTokens'
import useLoadUser from 'features/auth/utils/useLoadUser'
import { Loading } from '@optimization/ssi-common'

const LoadUser = ({ children }: { children: React.ReactNode }) => {
  const authTokensState = useLoadAuthTokens()
  const userState = useLoadUser()
  const isLoading = authTokensState.isLoading || userState.isLoading
  const isError = userState.isError

  return (
    <>
      <Loading isLoading={isLoading} isError={isError} />
      {children}
    </>
  )
}

export default LoadUser
