import classes from './style.module.scss'
import useLogout from 'features/auth/utils/useLogout'
import { APP_TITLE } from 'app/config'
import { useAppSelector } from 'app/hooks'
import { useIsAuthenticated } from '@azure/msal-react'
import { usernameSelector } from 'features/auth/authSlice'
import {
  TdsHeader,
  TdsHeaderTitle,
  TdsHeaderLauncher,
  TdsHeaderLauncherListTitle,
  TdsHeaderLauncherList,
  TdsHeaderLauncherListItem,
  TdsHeaderBrandSymbol,
} from '@scania/tegel-react'

const Header = () => {
  const username = useAppSelector(usernameSelector)
  const isAuthenticated = useIsAuthenticated()
  const handleLogout = useLogout()

  return (
    <div className={classes.header}>
      {isAuthenticated ? (
        <TdsHeader>
          <TdsHeaderTitle>{APP_TITLE}</TdsHeaderTitle>
          <TdsHeaderLauncher slot="end">
            <TdsHeaderLauncherListTitle>
              <div>{username} / Scania</div>
            </TdsHeaderLauncherListTitle>
            <TdsHeaderLauncherList>
              <TdsHeaderLauncherListItem>
                <button onClick={handleLogout}>Logout</button>
              </TdsHeaderLauncherListItem>
            </TdsHeaderLauncherList>
          </TdsHeaderLauncher>
          <TdsHeaderBrandSymbol slot="end">
            <a
              aria-label="Scania - red gryphon on blue shield"
              href="https://scania.com"
            >
              {' '}
            </a>
          </TdsHeaderBrandSymbol>
        </TdsHeader>
      ) : (
        <div>
          <TdsHeader>
            <TdsHeaderTitle>{APP_TITLE}</TdsHeaderTitle>
          </TdsHeader>
        </div>
      )}
    </div>
  )
}

export default Header
