import { useCallback, useState } from 'react'
import { TdsRadioButton, TdsToggle } from '@scania/tegel-react'
import classNames from 'classnames'
import styles from './style.module.scss'

interface Props {
  checked: boolean
  className?: string
  headline?: string
  label: string
  values: string[]
  onChange: (value: string | undefined) => void
}

const SidebarFIlterRadioGroup = ({
  checked,
  className,
  headline,
  label,
  values,
  onChange,
}: Props) => {
  const [selectedValue, setSelectedValue] = useState(values[0])
  const [buttonGroupEnabled, setButtonGroupEnabled] = useState(checked)

  const onChangeCallback = useCallback(
    (event: any) => {
      if (event.target.checked) {
        onChange(selectedValue)
      } else {
        onChange(undefined)
      }
      setButtonGroupEnabled(event.target.checked)
    },
    [onChange, selectedValue]
  )

  return (
    <div className={className}>
      <div className="tds-u-flex tds-u-align-center">
        <div className={classNames(styles['toggle-label'], 'tds-u-mr-auto')}>
          {label}
        </div>
        <TdsToggle
          headline={headline}
          checked={buttonGroupEnabled}
          onTdsToggle={onChangeCallback}
          size="lg"
        ></TdsToggle>
      </div>
      <fieldset className={styles['radio-fieldset']}>
        {values.map((buttonValue) => (
          <TdsRadioButton
            disabled={!buttonGroupEnabled}
            key={buttonValue}
            value={buttonValue}
            checked={buttonValue === selectedValue}
            onTdsChange={(event: any) => {
              if (event.target.value) {
                onChange(event.target.value)
                setSelectedValue(event.target.value)
              }
            }}
          >
            <div slot="label">{buttonValue}</div>
          </TdsRadioButton>
        ))}
      </fieldset>
    </div>
  )
}

export default SidebarFIlterRadioGroup
