import logOutAction from './logoutAction'
import { loginRequest } from 'app/config/auth'
import { setTokens } from 'features/auth/authSlice'
import { useAppDispatch } from 'app/hooks'
import { useCallback, useEffect, useState } from 'react'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { useVisibilityChange } from '@optimization/ssi-common'
import {
  AuthError,
  BrowserAuthError,
  InteractionRequiredAuthError,
  InteractionStatus,
} from '@azure/msal-browser'

const FOURTY_FIVE_MINUTES = 45 * 60 * 1000
const THIRTY_SECONDS = 30 * 1000

const useLoadAuthTokens = () => {
  const dispatch = useAppDispatch()
  const isAuthenticated = useIsAuthenticated()
  const { instance, accounts, inProgress } = useMsal()

  const [authTokensState, setAuthTokensState] = useState({
    isLoading: false,
    isError: false,
  })

  const refreshTokens = useCallback(() => {
    if (isAuthenticated) {
      setAuthTokensState({ isLoading: true, isError: false })

      let forceRefresh = false

      const exp = accounts?.[0]?.idTokenClaims?.exp

      if (exp) {
        const now = new Date()
        const expires = new Date(exp * 1000)
        const time = expires.getTime() - now.getTime()

        if (time < FOURTY_FIVE_MINUTES) {
          // Force refresh before the token expires but not too often.
          // forceRefresh must be set to true to extend logged in time.
          // When forceRefresh is false cache will be used in acquireTokenSilent which saves performance.
          // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4206
          forceRefresh = true
        }
      }

      const accessTokenRequest = {
        ...loginRequest,
        account: accounts[0],
        forceRefresh,
      }

      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((response) => {
          const payload = {
            idToken: response.idToken,
            accessToken: response.accessToken,
          }
          dispatch(setTokens(payload))
          setAuthTokensState({ isLoading: false, isError: false })
        })
        .catch((error: AuthError) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest)
          }
          if (error instanceof BrowserAuthError) {
            instance.logoutRedirect({
              onRedirectNavigate: (_) => {
                logOutAction()
                return false
              },
            })
          }
          setAuthTokensState({ isLoading: false, isError: true })
        })
    }
  }, [isAuthenticated, instance, accounts, dispatch])

  // Refresh the tokens on an interval
  useEffect(() => {
    const interval = setInterval(() => {
      refreshTokens()
    }, THIRTY_SECONDS)

    return () => clearInterval(interval)
  }, [refreshTokens])

  // Load the tokens on the initial page load
  useEffect(() => {
    refreshTokens()
  }, [refreshTokens])

  // Refresh the tokens when the current browser tab gets visible
  const onVisibilityChange = useCallback(() => {
    const state = document.visibilityState

    if (state === 'visible') {
      refreshTokens()
    }
  }, [refreshTokens])

  useVisibilityChange(onVisibilityChange)

  return {
    ...authTokensState,
    isLoading:
      authTokensState.isLoading || inProgress === InteractionStatus.Login,
  }
}

export default useLoadAuthTokens
