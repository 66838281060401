import classes from './style.module.scss'
import classNames from 'classnames'
import truckSide from './globe.png'
import useLogin from '../utils/useLogin'
import { Button } from '@optimization/ssi-common'

function Login() {
  const login = useLogin()

  return (
    <div className={classes.wrapper}>
      <div className={classes.left}>
        <div className={classes.top}>
          <div>
            <h2 className="tds-headline-01 tds-text-grey-400">
              Big data market
            </h2>
            <h2 className="tds-headline-01 tds-text-grey-400">
              analytics.
            </h2>
            <h1 className="tds-headline-01">
              Scania <strong>BEV</strong>ABLE
            </h1>
          </div>
          <Button
            onClick={login}
            dataTestid="button-login"
            variant="primary"
            size="lg"
            text="Explore potential"
            mode-variant="secondary"
            className="tds-u-mt3"
          />
        </div>
        <p className={classNames('tds-body-01', classes.bottom)}>
        <strong>BEV</strong>able enables a Scania perspective of our current and future 
        feasible BEV sales volumes, on a global, regional and market level. 
        Moreover, it generates leads for our markets by identifying BEV candidates, at scale
        </p>
      </div>
      <div className={classes.right}>
        <img src={truckSide} alt="Truck from side" />
      </div>
    </div>
  )
}

export default Login
