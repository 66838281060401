export interface Region {
  regionName: string
  Countries: string[]
}

const regionsFilter: Region[] = [
  {
    regionName: 'Americas',
    Countries: [
      'Argentina',
      'Uruguay',
      'Chile',
      'Colombia',
      'Mexico',
      'Peru',
      'Canada',
      'United States',
    ],
  },
  {
    regionName: 'Brazil',
    Countries: ['Brazil'],
  },
  {
    regionName: 'Asia-Oceania',
    Countries: [
      'China',
      'Hong Kong',
      'Taiwan',
      'Japan',
      'Australia',
      'New Zealand',
      'Malaysia',
      'Myanmar',
      'Philippines',
      'Singapore',
      'South Korea',
      'Thailand',
    ],
  },
  {
    regionName: 'India',
    Countries: ['India'],
  },
  {
    regionName: 'Eurasia, Middle East and Africa',
    Countries: [
      'Kenya',
      'Tanzania',
      'Uganda',
      'Bahrain',
      'Kuwait',
      'Oman',
      'Qatar',
      'Saudi Arabia',
      'United Arab Emirates',
      'Mali',
      'Morocco',
      'Senegal',
      'Kazakhstan',
      'Russia',
      'Botswana',
      'Malawi',
      'Mozambique',
      'Namibia',
      'South Africa',
      'Zambia',
      'Zimbabwe',
      'Ukraine',
      'Ghana',
    ],
  },
  {
    regionName: 'Central Europe',
    Countries: [
      'Belgium',
      'Luxembourg',
      'Netherlands',
      'Czech Republic',
      'Hungary',
      'Slovakia',
      'Poland',
      'Austria',
      'Germany',
      'Switzerland',
    ],
  },
  {
    regionName: 'Northern Europe',
    Countries: [
      'Estonia',
      'Latvia',
      'Lithuania',
      'Denmark',
      'Finland',
      'Great Britain',
      'Norway',
      'Sweden',
    ],
  },
  {
    regionName: 'Southern Europe',
    Countries: [
      'Bulgaria',
      'Romania',
      'Bosnia and Herzegovina',
      'Croatia',
      'Macedonia',
      'Montenegro',
      'Serbia',
      'Slovenia',
      'France',
      'Italy',
      'Portugal',
      'Spain',
    ],
  },
  {
    regionName: 'Independent Africa Region',
    Countries: [
      'Algeria',
      'Angola',
      'Egypt',
      'Eritrea',
      'Ethiopia',
      'Reunion',
      'Sudan',
      'Tunisia',
    ],
  },
  {
    regionName: 'Independent Americas Region',
    Countries: [
      'Bolivia',
      'Costa Rica',
      'Cuba',
      'Dominican Republic',
      'Ecuador',
      'Guatemala',
      'Honduras',
      'Panama',
      'Paraguay',
      'Trinidad and Tobago',
    ],
  },
  {
    regionName: 'Independent Asia Region',
    Countries: ['Bangladesh', 'Indonesia', 'Mongolia', 'Sri Lanka'],
  },
  {
    regionName: 'Independent Eurasia Region',
    Countries: [
      'Armenia',
      'Belarus',
      'Georgia',
      'Cyprus',
      'Greece',
      'Iceland',
      'Ireland',
      'Malta',
    ],
  },
  {
    regionName: 'Independent Middle East Region',
    Countries: [
      'Iran',
      'Iraq',
      'Israel',
      'Jordan',
      'Lebanon',
      'Syria',
      'Turkey',
    ],
  },
  {
    regionName: 'Independent Oceania Region',
    Countries: ['New Caledonia'],
  },
]

export default regionsFilter
