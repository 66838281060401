import { useRef, useEffect } from 'react';

const useOutsideClick = (
  callback: () => void,
  extraRef?: React.MutableRefObject<HTMLDivElement | null>
) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        ((extraRef?.current &&
          !extraRef.current.contains(event.target as Node)) ||
          !extraRef?.current)
      ) {
        callback();
      }
    };

    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [ref, extraRef, callback]);

  return ref;
};

export default useOutsideClick;
