import { ChassisEvaluationFilterRequest } from 'src/app/services/bevable'
import styles from './style.module.scss'
import { Dispatch, SetStateAction, useState } from 'react'
import countries from './country_filter.json'
import { TdsDropdown, TdsDropdownOption } from '@scania/tegel-react'
import regionsFilter from './regions_filter'

interface Props {
  initialValues: ChassisEvaluationFilterRequest
  onMarketUpdate: Dispatch<SetStateAction<ChassisEvaluationFilterRequest>>
}

const MarketFilter = ({ initialValues, onMarketUpdate }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filterValues, setFilterValues] = useState(initialValues)

  return (
    <div className={styles['filter-container']}>
      <TdsDropdown
        placeholder="Select markets to filter"
        className="mt-spacing-12"
        multiselect
        filter
        onTdsChange={(event) => {
          const newValues = { ...initialValues }
          if (event.detail.value.length === 0) newValues.Markets = []
          else newValues.Markets = event.detail.value.split(',')
          onMarketUpdate(newValues)
        }}
      >
        <>
          <div className={styles['market-header']}>Regions</div>
          {regionsFilter.map((region) => (
            <TdsDropdownOption
              key={region.regionName}
              value={region.Countries.toString()}
            >
              {region.regionName}
            </TdsDropdownOption>
          ))}
          <div className={styles['market-header']}>Countries</div>
          {countries.map((country) => (
            <TdsDropdownOption key={country} value={country}>
              {country}
            </TdsDropdownOption>
          ))}
        </>
      </TdsDropdown>
    </div>
  )
}

export default MarketFilter
