import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/store'

export interface AuthState {
  username: string
  idToken: string
  accessToken: string
  accessDenied: boolean
}

const initialState: AuthState = {
  username: '',
  idToken: '',
  accessToken: '',
  accessDenied: false,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<{ displayName: string }>) => {
      state.username = action.payload.displayName
    },
    setTokens: (
      state,
      action: PayloadAction<{ idToken: string; accessToken: string }>
    ) => {
      state.idToken = action.payload.idToken
      state.accessToken = action.payload.accessToken
    },
    setAccessDenied: (
      state,
      action: PayloadAction<{ accessDenied: boolean }>
    ) => {
      state.accessDenied = action.payload.accessDenied
    },
  },
})

export const { setUser, setTokens, setAccessDenied } = authSlice.actions

export const usernameSelector = (state: RootState) => state.auth.username

export default authSlice.reducer
