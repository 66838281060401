export const getErrorMessage = (error: unknown): string => {
  if (error && error instanceof Error) {
    if (error.stack) {
      return error.stack
    } else if (error.message) {
      return error.message
    }
  }

  return ''
}

// Todo: Call API to log error
export const logError = (message: string) => {
  console.log('logError', message)
}
