import classNames from 'classnames'
import { APP_TITLE, SUPPORT_EMAIL, SUPPORT_NAME } from 'app/config'

interface Props {
  className?: string
}

const PageAccessDenied = ({ className }: Props) => (
  <div className={classNames('tds-container', className)}>
    <h1 className="tds-headline-01">Access denied</h1>
    <p>
      Your global user does not have access to {APP_TITLE}.
      <br />
      Please contact {SUPPORT_NAME} (
      <a
        className="tds-text-grey-100 no-underline"
        href={`mailto:${SUPPORT_EMAIL}?subject=Need access to ${APP_TITLE}`}
      >
        {SUPPORT_EMAIL}
      </a>
      ) in order to request access.
    </p>
  </div>
)

export default PageAccessDenied
