import { BEVABLE_ENDPOINT } from 'app/config'
import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import { RootState } from '../store'

export enum Percentiles {
  P50th,
  P75th,
  P95th,
}

export enum Chargeable {
  TopUp,
  PublicCharging,
}

export interface ChassisEvaluationFilterRequest {
  Resolution: number
  Year?: number
  DailyDistance: Percentiles
  MaxGtw: Percentiles
  FilterOnChassisAdaptation: boolean
  FilterOnWheelConfiguration: boolean
  FilterOnAxleDistance: boolean
  FilterOnChassisHeight: boolean
  FilterOnCabType: boolean
  Chargable?: Chargeable
  Markets: string[]
}

export interface IndividualHexRequest {
  Resolution: number
  Year?: number
  DailyDistance: Percentiles
  MaxGtw: Percentiles
  FilterOnChassisAdaptation: boolean
  FilterOnWheelConfiguration: boolean
  FilterOnAxleDistance: boolean
  FilterOnChassisHeight: boolean
  FilterOnCabType: boolean
  Chargable?: Chargeable
  Markets: string[]
  H3CellIndex: string
}
export interface ChassisEvaluation {
  H3CellIndex: string
  ExecutableCount: number
  NonExecutableCount: number
}

export type StopsResponse = {
  TotalChassisEvaluated: number
  TotalExecutableCount: number
  TotalNonExecutableCount: number
  ChassisEvaluations: ChassisEvaluation[]
}

export type Company = {
  CompanyName: string
  ChassisEvaluated: number
  ExecutableCount: 0
  NonExecutableCount: number
  Designators: any[]
}

export type HexRespone = {
  TotalChassisEvaluated: number
  TotalExecutableCount: number
  TotalNonExecutableCount: number
  Companies: Company[]
}

const baseQuery = fetchBaseQuery({
  baseUrl: `${BEVABLE_ENDPOINT}/api`,
  credentials: 'same-origin',
  prepareHeaders: (headers, { getState }) => {
    const idToken = (getState() as RootState).auth.idToken
    if (idToken) {
      headers.set('authorization', `Bearer ${idToken}`)
    }
    return headers
  },
})

const baseQueryRetry = retry(baseQuery, {
  maxRetries: 0,
})

export const tagTypes = ['Stops', 'Hexes'] as const
export const bevableApi = createApi({
  reducerPath: 'bevableApi',
  baseQuery: baseQueryRetry,
  tagTypes,
  endpoints: (build) => ({
    getStops: build.query<any, ChassisEvaluationFilterRequest>({
      query: (request) => ({
        url: `ChassisData`,
        method: 'POST',
        body: request ? request : {},
      }),
      providesTags: () => [{ type: 'Stops' }],
      transformResponse: (response: StopsResponse) => response,
    }),
    getHex: build.query<any, IndividualHexRequest>({
      query: (request) => ({
        url: `ChassisData/h3CellIndex`,
        method: 'POST',
        body: request ? request : {},
      }),
      providesTags: () => [{ type: 'Hexes' }],
      transformResponse: (response: HexRespone) => response,
    }),
  }),
})

export const { useGetStopsQuery, useGetHexQuery } = bevableApi
