import { useCallback } from 'react'
import { TdsToggle } from '@scania/tegel-react'
import classNames from 'classnames'
import styles from './style.module.scss'

interface Props {
  checked: boolean
  className?: string
  headline?: string
  label: string
  onChange: (value: boolean) => void
}

const SidebarFilterToggle = ({
  checked,
  className,
  headline,
  label,
  onChange,
}: Props) => {
  const onChangeCallback = useCallback(
    (event: any) => {
      onChange(event.target.checked)
    },
    [onChange]
  )

  return (
    <div className={className}>
      <div className="tds-u-flex tds-u-align-center">
        <div className={classNames(styles['toggle-label'], 'tds-u-mr-auto')}>
          {label}
        </div>
        <TdsToggle
          headline={headline}
          checked={checked}
          onTdsToggle={onChangeCallback}
          size="lg"
        ></TdsToggle>
      </div>
    </div>
  )
}

export default SidebarFilterToggle
