import { Dispatch, SetStateAction } from 'react'
import SidebarFilterToggle from './SidebarFIlterToggle'
import SidebarFilterRadioGroup from './SidebarFilterRadioGroup'
import styles from './style.module.scss'
import {
  ChassisEvaluationFilterRequest,
  Percentiles,
} from 'src/app/services/bevable'

interface Props {
  initialValues: ChassisEvaluationFilterRequest
  onFilterUpdate: Dispatch<SetStateAction<ChassisEvaluationFilterRequest>>
}

const EvaluationOfChassisFilters = ({
  initialValues,
  onFilterUpdate,
}: Props) => {
  return (
    <div className={styles['filter-container']}>
      <SidebarFilterRadioGroup
        checked={true}
        label="DAILY DISTANCE"
        values={['Median day', 'Longer day', 'Longest day']}
        onChange={(value) => {
          let enumValue
          switch (value) {
            case 'Median day': {
              enumValue = Percentiles.P50th
              break
            }
            case 'Longer day': {
              enumValue = Percentiles.P75th
              break
            }
            case 'Longest day': {
              enumValue = Percentiles.P95th
              break
            }
            default: {
              throw Error
            }
          }
          const newValues = { ...initialValues }
          newValues.DailyDistance = enumValue
          onFilterUpdate(newValues)
        }}
      />
      <SidebarFilterRadioGroup
        checked={true}
        label="MAX GTW"
        values={['Median day', 'Heavier day', 'Heaviest day']}
        onChange={(value) => {
          let enumValue
          switch (value) {
            case 'Median day': {
              enumValue = Percentiles.P50th
              break
            }
            case 'Heavier day': {
              enumValue = Percentiles.P75th
              break
            }
            case 'Heaviest day': {
              enumValue = Percentiles.P95th
              break
            }
            default: {
              throw Error
            }
          }
          const newValues = { ...initialValues }
          newValues.MaxGtw = enumValue
          onFilterUpdate(newValues)
        }}
      />
      <SidebarFilterToggle
        checked={initialValues.FilterOnChassisAdaptation}
        label="CHASSIS ADAPTATION"
        onChange={(value) => {
          const newValues = { ...initialValues }
          newValues.FilterOnChassisAdaptation = value
          onFilterUpdate(newValues)
        }}
      />
      <SidebarFilterToggle
        checked={initialValues.FilterOnWheelConfiguration}
        label="WHEEL CONFIGURATION"
        onChange={(value) => {
          const newValues = { ...initialValues }
          newValues.FilterOnWheelConfiguration = value
          onFilterUpdate(newValues)
        }}
      />
      <SidebarFilterToggle
        checked={initialValues.FilterOnAxleDistance}
        label="AXLE DISTANCE"
        onChange={(value) => {
          const newValues = { ...initialValues }
          newValues.FilterOnAxleDistance = value
          onFilterUpdate(newValues)
        }}
      />
      <SidebarFilterToggle
        checked={initialValues.FilterOnChassisHeight}
        label="CHASSIS HEIGHT"
        onChange={(value) => {
          const newValues = { ...initialValues }
          newValues.FilterOnChassisHeight = value
          onFilterUpdate(newValues)
        }}
      />
      <SidebarFilterToggle
        checked={initialValues.FilterOnCabType}
        label="CAB TYPE"
        onChange={(value) => {
          const newValues = { ...initialValues }
          newValues.FilterOnCabType = value
          onFilterUpdate(newValues)
        }}
      />
    </div>
  )
}

export default EvaluationOfChassisFilters
