import logOutAction from './logoutAction'
import { useCallback } from 'react'
import { useMsal } from '@azure/msal-react'

const useLogout = () => {
  const { instance } = useMsal()

  return useCallback(() => {
    instance.logoutRedirect({
      onRedirectNavigate: (_) => {
        logOutAction()
        return false
      },
    })
  }, [instance])
}

export default useLogout
