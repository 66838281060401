import authReducer, { setAccessDenied } from 'features/auth/authSlice'
import { bevableApi } from './services/bevable'
import { logError } from 'common/utils/helper'
import { Matcher } from '@reduxjs/toolkit/dist/tsHelpers'
import {
  configureStore,
  createListenerMiddleware,
  ThunkAction,
  Action,
  isRejected,
  isAnyOf,
} from '@reduxjs/toolkit'
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit'

const getRejectedMatchers = (api: any): Matcher<any>[] =>
  Object.keys(api.endpoints).map((key) => api.endpoints[key].matchRejected)

const listenerMiddleware = createListenerMiddleware()

listenerMiddleware.startListening({
  matcher: isAnyOf(...getRejectedMatchers(bevableApi)),
  effect: async (action, listenerApi) => {
    const status = action?.meta?.baseQueryMeta?.response?.status

    if (status === 403) {
      listenerApi.dispatch(setAccessDenied({ accessDenied: true }))
    }
  },
})

const rtkQueryErrorLogger: Middleware =
  (_: MiddlewareAPI) => (next) => (action) => {
    if (isRejected(action)) {
      const error = action?.payload?.error
      const method = action?.meta?.baseQueryMeta?.request?.method
      const url = action?.meta?.baseQueryMeta?.request?.url

      if (error && method && url) {
        logError(`${error}: ${method} ${url}`)
      }
    }

    return next(action)
  }

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [bevableApi.reducerPath]: bevableApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(bevableApi.middleware, rtkQueryErrorLogger),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
